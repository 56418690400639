import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { css, StyleSheet } from 'aphrodite';
import { lumosOrange, layout, fontStyles } from '@lumoslabs/react_lux';

// util components
import ArrowIcon from 'components/ui/icons/ArrowIcon';

const styles = StyleSheet.create({
  link: {
    color: lumosOrange,
    margin: '0 8px',
    ':hover': {
      textDecoration: 'underline'
    }
  }
});

function ArrowNavigationLink({ direction, to, dataTrackLocation, dataTrackLinkClick, content }) {
  return (
    <Link
      to={to}
      data-track
      data-track-location={dataTrackLocation}
      data-track-link-click={dataTrackLinkClick}
      className={css(layout.centerVertical)}
    >
      {direction === 'backward' && <ArrowIcon orientation="left" fill={lumosOrange} />}
      <span className={css(fontStyles.a, styles.link)}>{content}</span>
      {direction === 'forward' && <ArrowIcon orientation="right" fill={lumosOrange} />}
    </Link>
  );
}

ArrowNavigationLink.propTypes = {
  direction: PropTypes.oneOf(['forward', 'backward']),
  to: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  dataTrackLinkClick: PropTypes.string.isRequired,
  dataTrackLocation: PropTypes.string
};

ArrowNavigationLink.defaultProps = {
  dataTrackLocation: 'body',
  direction: 'forward'
};

export default ArrowNavigationLink;
