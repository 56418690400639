import React from 'react';
import PropTypes from 'prop-types';
import { Graph } from 'react-structured-data';

import LandingPageSchema from 'components/landing/SchemaDotOrg';
import GameSchema from '../shared/SchemaDotOrg/GameSchema';

function SchemaDotOrg({ games }) {
  return (
    <LandingPageSchema>
      <Graph>{games.map(game => GameSchema({ game }))}</Graph>
    </LandingPageSchema>
  );
}

SchemaDotOrg.propTypes = {
  games: PropTypes.arrayOf(
    PropTypes.shape({
      longDescription: PropTypes.shape({ longDescription: PropTypes.string }),
      shortDescription: PropTypes.string,
      cardImage: PropTypes.shape({ file: PropTypes.shape({ imageUrl: PropTypes.string }) }),
      name: PropTypes.string.isRequired,
      freelyAccessible: PropTypes.bool.isRequired
    }).isRequired
  ).isRequired
};

export default SchemaDotOrg;
