import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { layout, mediaQuery, lumosPlum, OutlineButton, whiteAt98, fontStyles } from '@lumoslabs/react_lux';
import { Container, Row, Col } from 'reactstrap';

// utils
import i18n from 'utils/i18n';
import ScrollReveal from 'components/utils/ScrollRevealWrapper';

// styles
import buttonStyles from 'styles/helpers/buttons';

const styles = StyleSheet.create({
  backgroundWrapper: {
    backgroundColor: lumosPlum
  },
  headerCol: {
    marginBottom: 40
  },
  bodyCol: {
    marginBottom: 40
  },
  text: {
    textAlign: 'left',
    color: whiteAt98
  },
  ctaCol: {
    [mediaQuery.maxWidth.mobileLarge]: {
      justifyContent: 'center'
    }
  },
  whiteOutlineButton: {
    color: whiteAt98,
    borderColor: whiteAt98,
    ':hover': {
      backgroundColor: whiteAt98,
      color: lumosPlum
    }
  }
});

function ScienceAboutCategory({ title, body, locale }) {
  return (
    <section className={css(styles.backgroundWrapper)}>
      <Container className={css(layout.defaultContainerProperties)}>
        <Row>
          <Col xs="12" md={{ size: 10, offset: 2 }} className={css(styles.headerCol)}>
            <h2 className={css(fontStyles.h2, styles.text)}>{title}</h2>
          </Col>
          <Col xs="12" md={{ size: 10, offset: 2 }} className={css(styles.bodyCol)}>
            <p className={css(fontStyles.p, styles.text)}>{body}</p>
          </Col>
        </Row>
        <ScrollReveal animation="fadeIn">
          <Row>
            <Col xs="12" md={{ size: 10, offset: 2 }} className={css(layout.alignLeft, styles.ctaCol)}>
              <OutlineButton
                href={`/${locale}/science/`}
                styles={[buttonStyles.outlineButtonDefaults, styles.whiteOutlineButton]}
                track="true"
                trackButtonPress="goto_science_page"
                trackLocation="body"
              >
                {i18n.t('Games.GameCategoryPage.ScienceAboutCategory.learnMore')}
              </OutlineButton>
            </Col>
          </Row>
        </ScrollReveal>
      </Container>
    </section>
  );
}

ScienceAboutCategory.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
};

export default ScienceAboutCategory;
