import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { css, StyleSheet } from 'aphrodite';

// utils
import i18n from 'utils/i18n';

// util components
import ArrowNavigationLink from 'components/ui/navigation/ArrowNavigationLink';

const defaultStyles = StyleSheet.create({
  container: {
    maxWidth: 1200
  }
});

function GameCategoryNavigationLink({ locale, styles }) {
  return (
    <Container fluid className={css(defaultStyles.container, styles)}>
      <Row>
        <Col xs={12}>
          <ArrowNavigationLink
            to={`/${locale}/brain-games/`}
            direction="backward"
            dataTrackLinkClick="back_to_games_page"
            content={i18n.t('Games.GameCategoryPage.backToGameIndex')}
          />
        </Col>
      </Row>
    </Container>
  );
}

GameCategoryNavigationLink.propTypes = {
  locale: PropTypes.string.isRequired,
  styles: PropTypes.arrayOf(PropTypes.object)
};

GameCategoryNavigationLink.defaultProps = {
  styles: []
};

export default GameCategoryNavigationLink;
