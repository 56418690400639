import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { layout, mediaQuery, RoundedButton, whiteAt98, fontStyles } from '@lumoslabs/react_lux';
import { Container, Row, Col } from 'reactstrap';

// utils
import ScrollReveal from 'components/utils/ScrollRevealWrapper';

// styles
import buttonStyles from 'styles/helpers/buttons';

// util components
import HeroWrapper from 'components/ui/layout/HeroWrapper';

// background image
import background from '../images/background-circle-dots-and-atoms.svg';

const styles = StyleSheet.create({
  header: {
    color: whiteAt98,
    textAlign: 'center'
  },
  container: {
    marginBottom: '5vw',
    [mediaQuery.maxWidth.tablet]: {
      marginBottom: '20vw'
    }
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: 522,
    textAlign: 'center'
  },
  ctaCol: {
    marginTop: 16
  }
});

function EclipseHero({ header, ctaText }) {
  return (
    <HeroWrapper background={background} backgroundStyles={styles.image}>
      <Container className={css(layout.defaultContainerProperties, styles.container)}>
        <Row>
          <Col>
            <h1 className={css(fontStyles.h1, styles.header)}>{header}</h1>
          </Col>
        </Row>
        <ScrollReveal animation="fadeIn">
          <Row>
            <Col className={css(layout.centerHorizontal, styles.ctaCol)}>
              <RoundedButton
                value={ctaText}
                track="true"
                trackButtonPress="goto_signup"
                trackLocation="body"
                href="/sign_up"
                styles={[buttonStyles.buttonDefaults]}
              />
            </Col>
          </Row>
        </ScrollReveal>
      </Container>
    </HeroWrapper>
  );
}

EclipseHero.propTypes = {
  header: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired
};

export default EclipseHero;
