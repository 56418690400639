import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { lumosDarkBlue } from '@lumoslabs/react_lux';
import { StyleSheet } from 'aphrodite';

import Layout from 'components/layout';
import Metatags from 'components/Metatags';
import TestimonialCardGroup from 'components/landing/TestimonialCardGroup';

import EclipseHero from './EclipseHero';
import MainSubheader from './MainSubheader';
import ScienceAboutCategory from './ScienceAboutCategory';
import GameCardCollection from '../shared/GameCardCollection';
import GameCategoryNavigationLink from './GameCategoryNavigationLink';
import SchemaDotOrg from './SchemaDotOrg';

const styles = StyleSheet.create({
  lastNavLink: {
    marginBottom: 50
  },
  testimonials: {
    marginTop: 50
  }
});

function GameCategoryPage({
  data: { contentfulGameCategoryPage: content },
  location,
  pageContext: { locale, pagePath }
}) {
  const {
    title,
    pageHeader,
    pageHeaderCtaText,
    pageSubheader,
    pageSubheaderBody: { pageSubheaderBody },
    games,
    scienceHeader,
    scienceBody: { scienceBody },
    metaDescription: { metaDescription },
    openGraphImage: { openGraphCard, twitterImage }
  } = content;
  return (
    <Layout locale={locale} location={location} isStickyHeader accentColor={lumosDarkBlue}>
      <Metatags
        title={title}
        description={metaDescription}
        type="website"
        canonical={pagePath}
        locale={locale}
        thumbnail={openGraphCard.src}
        imageWidth={openGraphCard.width}
        imageHeight={openGraphCard.height}
        twitterImage={twitterImage.url}
      />
      <EclipseHero header={pageHeader} ctaText={pageHeaderCtaText} />
      <MainSubheader title={pageSubheader} body={pageSubheaderBody} />
      <GameCategoryNavigationLink locale={locale} />
      <GameCardCollection locale={locale} games={games} />
      <GameCategoryNavigationLink locale={locale} styles={[styles.lastNavLink]} />
      <ScienceAboutCategory title={scienceHeader} body={scienceBody} locale={locale} />
      <TestimonialCardGroup styles={[styles.testimonials]} />
      <SchemaDotOrg games={games} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query GameCategoryPageQuery($urlSlug: String!, $locale: String!) {
    contentfulGameCategoryPage(urlSlug: { eq: $urlSlug }, node_locale: { eq: $locale }) {
      title
      pageHeader
      pageHeaderCtaText
      pageSubheader
      pageSubheaderBody {
        pageSubheaderBody
      }
      games {
        ...gameCardFragment
        ...gameSchemaFragment
      }
      scienceHeader
      scienceBody {
        scienceBody
      }
      metaDescription {
        metaDescription
      }
      openGraphImage {
        openGraphCard: fixed(width: 1200, height: 630) {
          aspectRatio
          width
          height
          src
        }
        twitterImage: file {
          url
        }
      }
    }
  }
`;

GameCategoryPage.propTypes = {
  data: PropTypes.shape({
    contentfulGameCategoryPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      pageHeader: PropTypes.string.isRequired,
      pageHeaderCtaText: PropTypes.string.isRequired,
      pageSubheader: PropTypes.string.isRequired,
      pageSubheaderBody: PropTypes.shape({
        pageSubheaderBody: PropTypes.string.isRequired
      }).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      games: PropTypes.array.isRequired,
      scienceHeader: PropTypes.string.isRequired,
      scienceBody: PropTypes.shape({
        scienceBody: PropTypes.string.isRequired
      }).isRequired
    })
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired
  }).isRequired
};

export default GameCategoryPage;
