import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { layout, fontStyles } from '@lumoslabs/react_lux';
import { Container, Row, Col } from 'reactstrap';

const styles = StyleSheet.create({
  headerCol: {
    marginBottom: 24
  },
  centeredText: {
    textAlign: 'center'
  },
  superscript: {
    display: 'inline',
    verticalAlign: 'super',
    padding: '0 2px'
  }
});

function MainSubheader({ title, body }) {
  return (
    <Container className={css(layout.defaultContainerProperties)}>
      <Row>
        <Col xs="12" className={css(styles.headerCol)}>
          <h2 className={css(fontStyles.h2, styles.centeredText)}>{title}</h2>
        </Col>
        <Col xs="12">
          <p className={css(fontStyles.p, styles.centeredText)}>{body}</p>
        </Col>
      </Row>
    </Container>
  );
}

MainSubheader.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
};

export default MainSubheader;
